import querystring from 'querystring';

import React, { useCallback, useRef } from 'react';
import {
	ComboBox,
	InputContext,
	ListBox,
	ListBoxItem,
	Popover,
	RouterProvider,
	useContextProps,
} from 'react-aria-components';
import type { InputProps } from 'react-aria-components';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useUser } from '../../authentication';
import { path as newSearchPath, showNewSearch } from '../../new-search';
import { colors, effects } from '../../theme';
import ThemeSearch from '../../theme/components/search';
import searchBehavior from '../behavior';
import type { AlgoliaHit, SearchBehaviorProps } from '../behavior';

import QuickSearchResult, { NoHit } from './quick-search-result';
import type { AlgoliaNoHit } from './quick-search-result';

// 2 1px borders + 12px horizontal padding + 16px gap + 16px icon width
const StyledPopover = styled(Popover)<{ visible: boolean }>`
	${(props) => !props.visible && 'display: none;'}
	&[data-trigger='ComboBox'] {
		width: calc(var(--trigger-width) + 58px);
		margin-left: -45px;

		.react-aria-ListBox {
			${effects.shadow.hover}
			-webkit-overflow-scrolling: touch;
			background: ${colors.layer.layer};
			border-radius: 8px;
			border: 1px solid ${colors.border.subtle};
			max-height: 40vh;
			overflow-scrolling: touch;
			overflow-x: hidden;
			overflow-y: scroll;
		}
	}
`;

const SearchInput = React.forwardRef(function Search(
	props: React.ComponentProps<typeof ThemeSearch> & InputProps,
	ref: React.ForwardedRef<HTMLInputElement>,
) {
	// https://react-spectrum.adobe.com/react-aria/ComboBox.html#custom-children
	// eslint-disable-next-line no-param-reassign
	[props, ref] = useContextProps(props, ref, InputContext);

	return <ThemeSearch {...props} ref={ref} />;
});

interface ExternalProps {
	autoFocus?: boolean;
	placeholder?: string;
	textInputMode?: React.ComponentProps<typeof ThemeSearch>['mode'];
}

type Props = ExternalProps & SearchBehaviorProps;

function QuickSearch({
	autoFocus,
	filters,
	onChange,
	placeholder,
	results,
	textInputMode = 'light',
}: Props): JSX.Element {
	const popover = useRef<HTMLDivElement>(null);
	const navigate = useNavigate();
	const canShowNewSearch = showNewSearch(useUser());

	const navigateToAdvancedSearch = useCallback(() => {
		navigate({
			pathname: canShowNewSearch ? `/${newSearchPath}` : '/search',
			search: querystring.stringify({
				q: filters.query || '',
				...(canShowNewSearch ? { qType: 'keyword' } : {}),
			}),
		});
	}, [canShowNewSearch, filters.query, navigate]);
	const handleInputChange = useCallback(
		(query: string) => {
			onChange({ query, 'NOT data.num_total_emails': '=0' });
		},
		[onChange],
	);
	const handleKeyDown = useCallback(
		(event: React.KeyboardEvent<HTMLInputElement>) => {
			switch (event.key) {
				case 'Enter': {
					const listBoxElement = popover?.current?.querySelector(
						'.react-aria-ListBox',
					);
					if (!listBoxElement) {
						navigateToAdvancedSearch();
						return;
					}

					// https://react-spectrum.adobe.com/react-aria/ComboBox.html#listboxitem-1
					// ListBoxItem's are guaranteed to have `data-focused` when hovered or navigated to via arrow keys.
					// If the user has interacted with a link and presses enter, we should not navigate to the advanced search page.
					// ComboBox will handle the navigation for us via the <RouterProvider />
					const activeLink = Array.from(
						listBoxElement.childNodes,
					).some(
						(node) =>
							node instanceof HTMLElement
							&& 'focused' in node.dataset
							&& node.dataset.focused === 'true',
					);

					if (!activeLink) {
						navigateToAdvancedSearch();
					}
					break;
				}
				case 'Escape': {
					if ((filters.query || '').length === 0) {
						event.currentTarget.blur();
					}
					break;
				}
				default:
				// Do nothing
			}
		},
		[filters, navigateToAdvancedSearch],
	);
	let comboBoxResults: Array<AlgoliaHit | AlgoliaNoHit> = [NoHit];
	if (results && results.length > 0) {
		comboBoxResults = results;
	}

	return (
		<RouterProvider navigate={navigate}>
			<ComboBox
				allowsCustomValue
				aria-label="quick-search"
				autoFocus={autoFocus}
				items={comboBoxResults}
				inputValue={filters.query || ''}
				onInputChange={handleInputChange}
				onKeyDown={handleKeyDown}
			>
				<SearchInput mode={textInputMode} placeholder={placeholder} />
				<StyledPopover
					ref={popover}
					visible={Boolean(results && results.length > 0)}
				>
					<ListBox<AlgoliaHit | AlgoliaNoHit>>
						{(result) => {
							const id = `/${result.type}/${result.id}`;
							return (
								<ListBoxItem
									id={id}
									href={
										result.type === 'no-results'
											? void 0
											: id
									}
									textValue={id}
								>
									{(itemProps) => (
										<QuickSearchResult
											{...itemProps}
											result={result}
										/>
									)}
								</ListBoxItem>
							);
						}}
					</ListBox>
				</StyledPopover>
			</ComboBox>
		</RouterProvider>
	);
}

export default searchBehavior()(QuickSearch);
