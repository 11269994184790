import React, { lazy } from 'react';

import { isDriveTenant, isTalentPartner, or } from '../components/Authorized';
import authorizedRoute from '../components/authorized-route';

export { stageChipColors } from './job-order-detail';

const View = lazy(() => import('./view'));

export const routes = authorizedRoute(
	{
		path: 'talent',
		element: <View />,
		children: [{ path: '*', element: <View /> }],
	},
	or(isDriveTenant, isTalentPartner),
);
