import assertExhaustive from '../utils/assert-exhaustive';

import { Referrer } from './types';

// Safely encode/decode arbitrary strings to/from base64
// https://developer.mozilla.org/en-US/docs/Web/API/btoa#unicode_strings
// https://developer.mozilla.org/en-US/docs/Glossary/Base64#the_unicode_problem
function base64ToBytes(base64: string): string {
	const binString = atob(base64);
	return new TextDecoder().decode(
		Uint8Array.from(binString, (c) => c.charCodeAt(0)),
	);
}

function bytesToBase64(bytes: Uint8Array) {
	const binString = Array.from(bytes, (byte) =>
		String.fromCodePoint(byte),
	).join('');
	return btoa(binString);
}

function encode(
	query: Record<string, string | Array<Record<string, string | number>>> = {},
) {
	return encodeURIComponent(
		bytesToBase64(new TextEncoder().encode(JSON.stringify(query))),
	);
}

export function buildReferrerQuery(data: Referrer) {
	let referrerQuery = {};
	const source = data.source;
	switch (source) {
		case 'company_search':
			if (data.marketMapId == null) {
				referrerQuery = {
					search: data.search,
					source,
				};
			} else {
				referrerQuery = {
					market_map_id: data.marketMapId.toString(),
					search: data.search,
					source,
				};
			}
			break;
		case 'market_map_company_recommendation':
			referrerQuery = {
				companies: data.companies.map((company) => ({
					description: company.description,
					embedding_model_version: company.embeddingModelVersion,
					id: company.id,
				})),
				market_map_id: data.marketMapId.toString(),
				recommended_region_id: data.recommendedRegionId
					? data.recommendedRegionId.toString()
					: '',
				source,
			};
			break;
		case 'similar_companies':
			referrerQuery = {
				attribution_type: data.attributionType,
				query: data.query.map((company) => ({
					description: company.description,
					embedding_model_version: company.embeddingModelVersion,
					id: company.id,
					name: company.name,
				})),
				source,
			};
			break;
		default:
			assertExhaustive(source);
	}

	return referrerQuery;
}

export function encodeReferrerQuery(data: Referrer) {
	return encode(buildReferrerQuery(data));
}

export function decodeReferrerQuery(base64EncodedQueryString: string | null) {
	if (!base64EncodedQueryString) return null;

	return JSON.parse(base64ToBytes(base64EncodedQueryString)) as Referrer;
}
