import React, { lazy } from 'react';

import authorizedRoute from '../components/authorized-route';

const View = lazy(() => import('./view'));

export const routes = authorizedRoute(
	{
		path: 'highbeam',
		element: <View />,
		children: [{ path: '*', element: <View /> }],
	},
	'contacts.funnel',
);
