import React, { lazy } from 'react';

import authorizedRoute from '../components/authorized-route';

const View = lazy(() => import('./SemiAnnuals'));

export const routes = authorizedRoute(
	{
		path: 'semiannuals',
		element: <View />,
		children: [{ path: '*', element: <View /> }],
	},
	'contacts.semiannuals',
);
