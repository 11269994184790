import React, { lazy } from 'react';

import { or } from '../components/Authorized';
import authorizedRoute from '../components/authorized-route';

const View = lazy(() => import('./view'));

export const routes = authorizedRoute(
	{
		path: 'watchlists',
		element: <View />,
		children: [{ path: '*', element: <View /> }],
	},
	or('contacts.watchlists', 'contacts.limited_watchlists'),
);
